import ApiService from "@/core/services/api.service";

const Get = async (resource, textId, valueId, params = null) => {
  const config = params ? { params } : {};
  return ApiService.get("common", resource, config)
    .then(({ data }) => {
      return Format(data.response ?? [], textId, valueId);
    })
    .catch(() => { });
};

const Format = (data, textId, valueId) => {
  return data.map(item => ({ text: item[textId], value: parseInt(item[valueId]) }));
}

// CommonData's
const CommonData = {
  getTodosPaises: async () => {
    return await Get("todos_paises", "nome", "idpais");
  },
  getPais: async () => {
    return await Get("pais", "nome", "idpais");
  },
  getEstado: async (idpais) => {
    return await Get("estado", "nome", "idestado", { idpais });
  },
  getMunicipio: async (idestado) => {
    return await Get("municipio", "nome", "idmunicipio", { idestado });
  },
  getTipoPlanoInvestimento: async (tipo_plano_investimento) => {
    return await Get("tipo_plano_investimento", "nome", "idtipo_plano_investimento", { tipo_plano_investimento });
  },
  getProduto: async (idplano_investimento) => {
    return await Get("produto", "descricao", "idpip_produto", { idplano_investimento });
  },
  getMoeda: async () => {
    return await Get("moeda", "descricao", "idmoeda");
  },
  getTipoDespesaOperacional: async () => {
    return await Get("tipo_despesa_operacional", "descricao", "idtipo_despesa_operacional");
  },
  getDeducao: async () => {
    return await Get("deducao", "descricao", "iddeducao");
  }
}

export default CommonData;
