<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="true"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        class="mb-0"
      >
        <template #label> Dedução <span class="text-danger">*</span> </template>
        <template #default>
          <KTSelect
            v-model="form.iddeducao"
            :options="form_options.deducao"
            :required="!iddeducao && !finalizado"
            :disabled="!!iddeducao || finalizado"
          />
        </template>
      </b-form-group>

      <b-row>
        <b-col
          md="6"
          lg="4"
          xl="3"
          v-for="(ano, index) in anos"
          :key="'ano-' + ano"
        >
          <b-card no-body class="p-3 mt-8">
            <p class="font-weight-bold">Ano {{ ano }}</p>
            <b-form-group label-size="lg" class="mb-0">
              <template #label>
                Percentual <span class="text-danger">*</span>
              </template>
              <template #default>
                <b-input-group :prepend="simbolo_moeda">
                  <KTInputNumeric
                    v-model="form.anos[index].percentual"
                    :precision="2"
                    autocomplete="off"
                    :required="!finalizado"
                    :disabled="finalizado"
                  />
                </b-input-group>
              </template>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </KTCard>
</template>

<script>
import CommonDataService from "@/core/services/commondata.service";
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipDeducaoForm",
  props: {
    idplano_investimento: {
      required: true
    },
    iddeducao: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-file-invoice-dollar",
        title: "Demonstrativo de Resultados do Exercício - DRE",
        description: `Selecione na lista a dedução e em seguida o respectivo percentual
          para cada ano. Este preenchimento é, especialmente importante para empresas
          enquadradas no Simples Nacional, onde as alíquotas mudam de acordo com a faixa
          de faturamento. Independente, do tipo de empresa, consulte um contador para
          obter informações precisas sobre as alíquotas a serem utilizadas.`
      },
      form: {
        iddeducao: null,
        anos: []
      },
      form_options: {
        deducao: []
      },
      anos: 0,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/dre`;
    },
    manual() {
      return {
        session: "dre",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_dre_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [
        this.Api.GetData(this.form.iddeducao),
        CommonDataService.getDeducao()
      ];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;
        const R_DEDUCAO = 1;

        this.anos = res[R_GETDATA].anos;
        this.simbolo_moeda = res[R_GETDATA].simbolo_moeda;
        this.form_options.deducao = res[R_DEDUCAO];

        if (res[R_GETDATA].deducao && res[R_GETDATA].deducao.length) {
          this.form.anos = res[R_GETDATA].deducao;
        } else {
          this.form.anos = [];
          for (let i = 1; i <= this.anos; i++) {
            this.form.anos.push({
              idpip_produto: this.idpip_produto,
              ano: i
            });
          }
        }
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmitUpdate(this.form.iddeducao, this.form.anos);
    }
  },
  mounted() {
    if (this.iddeducao) {
      this.form.iddeducao = this.iddeducao;
    }

    this.getPageData();
  }
};
</script>
